<template>
  <div>
    <validation-observer ref="observer">
      <b-form>
        <template v-for="item in fields">
          <b-row v-if="checkShow(item)">
            <template v-for="item in getItems(item)">
              <template v-if="checkShow(item)">
                <b-col :cols="item.group_col !== undefined ? item.group_col : 6" :style="item.style">
                  <template v-if="item.type === 'title'">
                    <hr class="mb-1 w-100"/>
                    <h4 class="mb-1 font-weight-bold">{{ $t(item.label) }}</h4>
                  </template>

                  <template v-else-if="item.type === 'hr'">
                    <hr class="mt-1 mb-2 w-100"/>
                  </template>

                  <template v-else-if="item.type === 'blank'">
                    <div class="w-100" :style="item.style">&nbsp;</div>
                  </template>

                  <b-form-group
                    v-else :label-cols="item.label_col !== undefined ? item.label_col : 3"
                    :description="$t(item.description)"
                  >
                    <template slot="label">
                        <span v-if="item.show_label !== false">
                          {{ $t(item.label) }}
                          <span v-if="item.rule && item.rule.indexOf('required') !== -1 " style="color: red">*</span>
                        </span>
                    </template>

                    <template v-if="item.type === 'slot' && item.validate_in_slot === true">
                      <input type="hidden" v-model="rowData[item.name]"/>
                      <slot :name="item.slot" :rowData="rowData" :isEdit="isEdit" :slotConfig="item.slot_config"  :rootItem="item"></slot>
                    </template>

                    <template v-else-if="item.type === 'date'">
                      <b-input-group class="flatpickr">
                        <flat-pickr
                          class="form-control"
                          v-model="rowData[item.name]"
                          :config="{wrap: true}"
                        />
                        <b-input-group-append>
                          <b-button
                            variant="outline-primary"
                            data-clear
                          >
                            <!--{{ $t('common.clear') }}-->
                            <feather-icon icon="TrashIcon"/>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t(item.label)"
                        :rules="item.rule"
                        :customMessages="item.custom_messages"
                      >
                        <input type="hidden" :value="rowData[item.name]"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </template>

                    <validation-provider
                      v-else #default="{ errors }"
                      :name="$t(item.label)"
                      :rules="item.rule"
                      :customMessages="item.custom_messages"
                    >

                      <template v-if="item.type === 'slot' && !item.validate_in_slot">
                        <input type="hidden" v-model="rowData[item.name]"/>
                        <slot :name="item.slot" :rowData="rowData" :isEdit="isEdit"></slot>
                      </template>


                      <b-input-group
                        v-if="['text', 'number'].includes(item.type)"
                        :append="item.append"
                      >
                        <b-form-input
                          :id="item.name" :type="item.type" v-model="rowData[item.name]"
                          :disabled="item.edit_disable === true && isEdit"
                          :placeholder="item.placeholder"
                        />
                      </b-input-group>

                      <template v-if="item.type === 'radio'">
                        <b-form-radio
                          v-for="option in item.options" :name="item.name" :value="option.value"
                          v-model="rowData[item.name]"
                        >
                          {{ option.text }}
                        </b-form-radio>
                      </template>

                      <div style="padding: calc(0.438rem + 1px) 0" v-if="item.type === 'checkbox'">
                        <template v-for="(option, optionIndex) in item.options">
                          <b-form-checkbox
                            v-if="option.fixed !== false || (option.fixed === false && rowData[item.name] && (rowData[item.name].includes(option.value) || rowData[item.name].includes(option.parent_id)))"
                            :name="item.name"
                            :value="option.value"
                            v-model="rowData[item.name]"
                            :class="{'mt-05': optionIndex !== 0, 'ml-2': option.parent_id}"
                            @input="inputCheckbox(item.name, option.parent_id, option.value, item.parent)"
                            :disabled="!!option.disabled"
                          >
                            {{ option.text }}
                          </b-form-checkbox>
                        </template>
                      </div>

                      <template v-if="item.type === 'time'">
                        <b-form-input type="time" v-model="rowData[item.name]"/>
                      </template>

                      <template v-if="item.type === 'select'">
                        <v-select
                          :multiple="item.multiple"
                          label="text"
                          :options="item.options"
                          v-model="rowData[item.name]"
                          :reduce="text => text.value"
                          :disabled="(item.add_disable === true && !isEdit) || (item.edit_disable === true && isEdit)"
                          :clearable="item.clearable === true"
                          :searchable="item.searchable === true"
                        >
                          <template #no-options>
                            {{ $t('common.no_options') }}
                          </template>
                        </v-select>
                      </template>

                      <template v-if="item.type === 'textarea'">
                        <b-form-textarea rows="3" max-rows="8" v-model="rowData[item.name]"/>
                      </template>

                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
            </template>


          </b-row>
        </template>
      </b-form>

      <hr class="invoice-spacing">
      <button-permission
        variant="primary"
        @click="submitForm"
        :permission="permission"
      >
        {{ submitBtnText }}
      </button-permission>
    </validation-observer>

  </div>

</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  max,
  digits,
  alphaDash,
  length,
} from '@validations'
import common from '@/common'
import ButtonPermission from '@/components/ButtonPermission'

export default {
  name: 'EditField',
  components: {
    ButtonPermission,
    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: []
    },
    rowData: {
      type: Object,
      default: {}
    },
    postApi: {
      type: String,
      default: ''
    },
    finishRouter: {
      type: String,
      default: ''
    },
    permission: { type: String },
    submitBtnText: {
      type: String,
      default: function () {
        return this.$t('common.submit')
      }
    },
    submitAction: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      checkbox_timestamp: 0,
      common: common,
      test: false,

    }
  },
  methods: {
    resetField(name) {
      this.rowData[name] = ''
      // this.$refs[`flat-pickr-${name}`].$el.value = ''

    },
    inputCheckbox: function (field_name, parent_id, current_id, parent) {
      if (!event) return
      if (event.type === 'readystatechange') return
      if (this.checkbox_timestamp === event.timeStamp) return
      if (!parent) return

      // checkbox oninput 方法会调用多次，这种方式可以禁止多次调用
      this.checkbox_timestamp = event.timeStamp

      if (parent_id) {
        let flag = false
        for (const i in parent[parent_id]) {
          if (this.rowData[field_name].includes(parent[parent_id][i])) {
            flag = true
          }
        }

        if (flag) {
          this.rowData[field_name] = this.pushElement(this.rowData[field_name], parent_id)
        } else {
          this.rowData[field_name] = this.removeElement(this.rowData[field_name], parent_id)
        }

      } else if (parent[current_id]) {
        for (const i in parent[current_id]) {
          if (this.rowData[field_name].includes(current_id)) {
            this.rowData[field_name] = this.pushElement(this.rowData[field_name], parent[current_id][i])
          } else {
            this.rowData[field_name] = this.removeElement(this.rowData[field_name], parent[current_id][i])
          }
        }
      }
    },

    pushElement(arr, element) {
      if (!arr.includes(element)) {
        arr.push(element)
      }

      return arr
    },

    removeElement(arr, element) {
      const index = arr.indexOf(element)
      if (index > -1) {
        arr.splice(index, 1)
      }

      return arr
    },

    transData: function () {
      for (const i in this.fields) {
        if (this.fields[i].type === 'number') {
          this.rowData[this.fields[i].name] = parseFloat(this.rowData[this.fields[i].name])
        }
      }
    },

    submitForm: function () {
      this.transData()
      if (this.$listeners['beforeSubmit']) {
        const that = this
        this.$emit('beforeSubmit', (isReady, data = undefined) => {
          if (isReady === true) {
            that.runSubmit(data)
          }
        })
      } else {
        this.runSubmit()
      }
    },

    runSubmit: function (data = undefined) {
      data = data ? data : this.rowData
      this.$refs.observer.validate()
        .then(success => {
          if (success && !this.submitAction) {
            common.apiPostData(this.postApi, data)
              .then(res => {
                this.$router.push({ name: this.finishRouter })
                if (this.$listeners['afterSubmit']) {
                  this.$emit('afterSubmit', this.rowData, res.data.facilityID)
                }
              })
          } else if (success) {
            this.submitAction(this.rowData)
          }
        })
    },

    getItems: function (data) {
      if (data.multi_col) {
        return data.col_list
      } else {
        return [data]
      }

    },
    checkShow: function (item) {
      const Assertion1 = (item.show === true
        || (item.show_value === undefined && this.rowData[item.show])
        || (item.show_value !== undefined && this.rowData[item.show] === item.show_value)
        || (item.show === undefined && item.add_show === undefined  && item.edit_show === undefined)
        || (item.show === undefined && item.add_show === true && this.isEdit === false)
        || (item.show === undefined && item.edit_show === true && this.isEdit === true)
        || eval(item.show_exp))
      const Assertion2 = (!item.show_fn || (typeof item.show_fn === 'function' && item.show_fn(this)))
      // console.log(item.label, Assertion1, Assertion2)
      return Assertion1 && Assertion2
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.input-group-append .input-group-text {
  min-width: 60px;
}
</style>
