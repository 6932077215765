<template>
  <div>
    <div class="d-flex align-items-baseline flex-wrap mb-2">
      <div class="d-flex align-items-center mr-1">
        <feather-icon v-if="showBackBtn" size="24" class="mr-1" icon="ArrowLeftIcon" @click="$emit('routerBack')"/>
        <h4 class="font-weight-bold m-0">{{ title }}</h4>
      </div>
      <div v-if="subTitle" class="font-weight-bold font-small-4 mt-05" :class="subTitleClass">{{ subTitle }}</div>
    </div>
    <hr v-if="showHr" class="mb-2">
  </div>
</template>

<script>
import {
  BCardTitle, BCardHeader
} from 'bootstrap-vue'

export default {
  name: 'EditFieldHeader',
  components: {
    BCardTitle,
    BCardHeader
  },
  props: {
    title: { type: String },
    subTitle: { type: String, default: '' },
    subTitleClass: { type: String, default: '' },
    showBackBtn: {
      type: Boolean,
      default: true
    },
    showHr: {
      type: Boolean,
      default: true
    },
  }
}
</script>

<style scoped>
</style>
